@import '../../assets/scss/variables';
@import "../../assets/scss/main";

.arrow-button {
  display: inline-block;
  padding: 3px 0 6px;
  border-radius: 14px;
  font: 700 14px Lato, sans-serif;
  letter-spacing: 0px;
  white-space: nowrap;
  transition: $hover-transition;
  outline: none;
  text-decoration: none;
  transition-property: color, opacity;
}

.hover-arrow {
  position: relative;
  top: 3px;
  margin-left: 3px;
  stroke-width: 2;
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
}

.hover-arrow-line {
  opacity: 0;
  transition: opacity $hover-transition;
}

.hover-arrow-tip {
  transition: transform $hover-transition;
}

@media (pointer: fine) {
  a:hover .hover-arrow-line,
  button:hover .hover-arrow-line {
    opacity: 1;
  }

  a:hover .hover-arrow-tip,
  button:hover .hover-arrow-tip {
    transform: $arrow-hover-offset;
  }
}

@media (pointer: coarse) {
  a:active .hover-arrow-line,
  button:active .hover-arrow-line {
    opacity: 1;
  }

  a:active .hover-arrow-tip,
  button:active .hover-arrow-tip {
    transform: $arrow-hover-offset;
  }
}
