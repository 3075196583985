@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.head-container {  
  background-color: $blue-color;    
  width: 100%;    
  height: fit-content;      
  min-width: $min-content-width;
  @include flex(center, center, row);

  .head-body {         
    // padding-top: 30px;  
    width: 100%;    
    height: 480px;
    position: relative;
    padding-left: $default-padding-left;    
    max-width: $max-width;
    @include responsives(max-width, $xl-width, (height: 1120px, display: flex, flex-direction: column, align-items: center, padding-left: 0px));      
    @include responsives(max-width, $sm-width, (height: 1220px));      

    .hero-base {
      position: absolute;    
      right: 0;
      bottom: -8px;    
      margin-right: -200px;
      width: auto;
      height: 505px;
      object-fit: contain;
      z-index: 1;
    }

    .hero-phone {
      position: absolute;    
      right: 0;
      bottom: 0;
      margin-bottom: -70px;
      margin-right: 35px;
      height: 530px;
      width: 265px;
      object-fit: contain;
      z-index: 1;
  
      @include responsives(max-width, $xl-width, (position: inherit, order: 1, margin-top: 70px, margin-right: 0px));        
    }
  
    .content-body {
      position: relative;
      z-index: 2;
      max-width: 760px;
      @include responsives(max-width, $xl-width, (max-width: 550px));
      @include responsives(max-width, $sm-width, (max-width: $min-content-width));

      .head-title {
        margin-top: 100px;
        color: white;            
        padding: 8px 0px;
        
        font: normal normal bold 60px/78px Lato;
        letter-spacing: 0px;
        text-shadow: 0px 0px 3px #10203733;
  
        @include responsives(max-width, $xl-width, (font-size: 56px, line-height: 1.3));
        @include responsives(max-width, $sm-width, (text-align: left, font-size: 50px, line-height: 1.3));

        // span.pink-text {
        //   color: $pink-color;
        // }
      }
  
      .head-subtitle {
        margin-top: 16px;
        // padding: 8px 0px;
        // margin-bottom: 76px;
  
        font: normal normal normal 25px/28px Lato;
        letter-spacing: 0px;
        color: #FFFFFFCC;
        text-shadow: 0px 0px 3px #1020374D;
  
        @include responsives(max-width, $xl-width, (font-size: 20px, margin-bottom: 50px));     
        @include responsives(max-width, $sm-width, (text-align: left));   
      }  

      .head-actions {
        margin-top: 30px;
        @include flex(center, flex-start, row);
        @include responsives(max-width, $xl-width, (justify-content: center, flex-direction: column));
    
        .head-action {
          margin: 0px 0px 0px 14px;
          width: auto;

          &.apple {
            height: 46px;
          }

          &.google {
            height: 70px;
          }

          .btn-reserve-spot {
            width: 192px;
            height: 48px;
            background-color: $red-color;
            border: none;
            outline: 0;
            box-shadow: 0px 4px 18px rgba($color: $shadow-red-color, $alpha: 0.55);
            border-radius: 6px;
            color: white;    
            display: flex;
            justify-content: center;
            align-items: center;    
            cursor: pointer;       
            text-align: left;
            font: normal normal bold 16px/20px Lato;
            
            &:active {
              opacity: 0.8;
            }
          }
        }
      }

      // .email-container {           
      //   .email{          
      //     z-index: 2;
      //   }
      //   .btn-reserve-spot {
      //     z-index: 2;
      //   }
      // }  
    }
  }
}