@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.getstarted-container {
  margin-top: 148px;
  background-color: $blue-color;
  width           : 100%;
  height          : fit-content;
  position: relative;
  min-width: $min-content-width;    
  @include flex(center, center, row);

  .getstarted-body {
    height          : 300px;
    width: 100%;
    max-width: $max-width;
    @include flex(flex-start, flex-start, row);
    @include responsives(max-width, $xl-width, (align-items: center, height: 680px, flex-direction: column));    

    .content-body {
      position: relative;
      margin-top: 30px;       
      max-width: 530px;
      @include responsives(max-width, $xl-width, (margin-left: 0px));
      @include responsives(max-width, $sm-width, (flex-direction: column, display: flex, align-items: left, max-width: $min-content-width, text-align: left));      
    }

    .footer_signup_img {    
      margin-top: -50px;
      max-width: 350px;
      width: 100%;
      height: 350px;
      object-fit: contain;
      margin-left: 66px;
      margin-right: 60px;
    }

    .getstarted-header {
      color: white;    
      font: normal normal bold 42px/48px Lato;
      letter-spacing: 0.67px;
    }

    .getstarted-desc {
      color: #FFFFFFCC;
      font: normal normal normal 26px/36px Lato;
      letter-spacing: 0.42px;
      text-shadow: 0px 0px 3px #10203729;
      margin-bottom: 50px;
      margin-top: 16px;      
      @include responsives(max-width, $sm-width, (margin-bottom: 20px));
    }
  }
}