@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.email-container {
  @include flex(flex-start, justify-content, row);  
  @include responsives(max-width, $sm-width, (flex-direction: column));

  .email {
    height: 48px;
    width: 320px;    
    color: black;
    padding: 16px;
    border-radius: 6px;
    border: none;
    background: #ffffff 0% 0% no-repeat padding-box;    
    outline: 0;
    font: normal normal bold 16px/20px Lato;
    margin-right: 40px;
    @include responsives(max-width, $sm-width, (margin-bottom: 10px));
    @include responsives(max-width, $sm-width, (width: $min-content-width, margin-right: 0px));
  }

  .btn-reserve-spot {
    width: 170px;
    height: 48px;
    background-color: $red-color;
    border: none;
    outline: 0;
    box-shadow: 0px 4px 18px rgba($color: $shadow-red-color, $alpha: 0.55);
    border-radius: 6px;
    color: white;    
    display: flex;
    justify-content: center;
    align-items: center;    
    cursor: pointer;       
    text-align: left;
    font: normal normal bold 16px/20px Lato;
    @include responsives(max-width, $sm-width, (width: $min-content-width));
    
    &:active {
      opacity: 0.8;
    }
  }
}
