.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --ml-brand-main: #ee2a49;

  --ml-green-0: #4fcc9c;
  --ml-yellow-0: #ffcd56;
  --ml-purple-0: #6d64ad;

  /* shadow colors */
  --ml-shadow: #102037;

  /* blue colors */
  --ml-brand-blue: #00a0bc;
  --ml-blue-0: #4386cb;
  --ml-blue-1: #30b9f8;

  /* pink colors */
  --ml-pink-0: #ff6383;

  /* gray colors */
  --ml-gray-0: #fafafa;
  --ml-gray-1: #f7f9fa;
  --ml-gray-2: #f0f3f5;
  --ml-gray-3: #ebedf0;
  --ml-gray-4: #e1e3e6;
  --ml-gray-5: #b0b5ba;
  --ml-gray-6: #838c96;
  --ml-gray-7: #47525d;
  --ml-gray-8: #1b2224;

  /* for arrow button */
  --ctaFont: 400 14px Lato, sans-serif;
  --hoverTransition: 180ms cubic-bezier(0.46, 0, 0.3, 1);
}
