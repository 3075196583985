@import '../../../assets/scss/variables';
@import "../../../assets/scss/main";

.about-us-container {
  width: 100%;
  min-width: $min-content-width;  
  @include flex(center, center, column);

  .story-container {
    width: 100%;
    // Hide the background color when internship section is hidden
    // background-color: $light-white-color;
    @include flex(center, center, row);

    .section {
      padding: 120px $default-padding-left 96px $default-padding-left;
      width: fit-content;
      max-width: $max-width;
      @include responsives(max-width, $xl-width, (padding: 72px));
      @include responsives(max-width, $md-width, (padding: 24px));

      .blue-text {
        color: $blue-color;
      }
    }
  }

  // .team-container {
  //   width: 100%;
  //   @include flex(center, center, row);

  //   .team-body {
  //     padding: 0px $default-padding-left;
  //     width: 100%;
  //     max-width: $max-width;
  //     @include responsives(max-width, $md-width, (padding: 24px));

  //     .team-title {
  //       margin-top: 96px;
  //       font: normal normal bold 28px/36px Lato;
  //       letter-spacing: 0px;
  //       color: #60678E;
  //       @include responsives(max-width, 1352px, (text-align: center));
  //     }
  
  //     .team-members {
  //       margin-top: 44px;
  //       @include flex(center, space-between, row);
  //       flex-wrap: wrap;
  //       align-content: space-around;
  //       @include responsives(max-width, 1352px, (margin-top: 34px, justify-content: center));
  //     }
  //   }
  // }

  .job-container {
    margin-top: 96px;
    width: 100%;
    @include flex(center, center, row);

    .job-banner {
      margin: 0px 89px;
      padding: 24px;
      width: 100%;
      max-width: 1188px;
      background: $light-white-color 0% 0% no-repeat padding-box;
      border-radius: 8px;
      @include flex(center, center, column);
      @include responsives(max-width, $xl-width, (margin: 0px 72px));
      @include responsives(max-width, $md-width, (margin: 0px 24px));

      .job-title {
        font: normal normal bold 28px/36px Lato;
        letter-spacing: 0px;
        color: #0062FF;
        text-align: center;
      }

      .job-link {
        margin-top: 16px;
      }
    }
  }
}

.ant-modal.job-description-modal {
  width: 100%;
  max-width: $max-width;

  .ant-modal-content {
    @include flex(center, center, row);
  
    .ant-modal-close-icon {
      color: $ml-gray-6;
      height: 32px;
      width: 32px;
      background-color: #10203714;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 32px;
      position: relative;
      top: 16px;
      left: 8px;
      > svg {
        fill: $ml-gray-6;
        height: 32px;
        width: 32px;
      }
    }

    .ant-modal-body {
      padding: 96px 24px 114px 24px;
      width: 100%;
      max-width: 848px;
      letter-spacing: 0px;

      h1 {
        margin: 0px;
        margin-top: 2px;
        font: normal normal bold 28px/36px Lato;
        color: #0C1335CC;
      }

      h2 {
        margin: 0px;
        font: normal normal bold 16px/21px Lato;
        color: #989DB9;
        text-transform: uppercase;
      }

      h3 {
        margin: 0px;
        margin-top: 24px;
        font: normal normal bold 16px/21px Lato;
        color: #0062FF;
      }

      h4 {
        margin: 0px;
        margin-top: 32px;
        margin-bottom: 8px;
        font: normal normal normal 16px/24px Lato;
        color: #0C1335CC;
      }

      p, ul {
        margin: 0px;
        margin-top: 24px;
        font: normal normal normal 18px/27px Lato;
        color: #0C1335CC;
      }

      ul {
        padding-inline-start: 22px;
      }

      .apply-button {
        width: 127px;
        height: 48px;
      }
    }
  }
}
