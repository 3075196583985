@import "../../../assets/scss/main";

.middle-container {
  background-color: $light-white-color;
  width: 100%;
  height: fit-content;     
  position: relative;  
  min-width: $min-content-width;  
  @include flex(center, center, row);

  .section_bg1 {
    position: absolute;
    top: 0;
    right: 0;
    height: 580px;
    object-fit: contain;
  }

  .section_bg2 {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 380px;
    object-fit: contain;
  }

  .middle-body {
    width: 100%;
    height: 970px;    
    max-width: $max-width;
    padding-left: $default-padding-left;
    padding-top: 96px;
    @include responsives(max-width, $xl-width, (height: 1600px, padding-left: 0px));

    .section {        
      position: relative;    

      &.aim {
        @include flex(flex-start, flex-start, row);            
        @include responsives(max-width, $xl-width, (flex-direction: column, align-items: center));

        .aim-body{
          // padding: 24px;
          width: fit-content;          
          max-width: 500px;
          @include responsives(max-width, $xl-width, (order: 0, margin-bottom: 50px, padding: 24px));
        }
      }

      &.learn_apply {
        padding-top: 96px;
        @include flex(flex-start, flex-start, row);        
        @include responsives(max-width, $xl-width, (flex-direction: column, align-items: center));        

        .learn_apply-body{
          // padding: 24px;
          padding-right: $default-padding-left;
          width: fit-content;
          max-width: 600px;
          @include responsives(max-width, $xl-width, (order: 0, margin-bottom: 50px, padding: 24px));        
        }
      }

      .set_goals_img {
        width: 100%;
        max-width: 600px;
        // height: 470px;
        object-fit: contain;
        margin-top: 10px;
        margin-left: 48px;
        z-index: 1;        
        @include responsives(max-width, $xl-width, (order: 1, margin: 0px));  
      }

      .learn_apply_img {
        width: 100%;
        max-width: 600px;
        // height: 470px;
        object-fit: contain;      
        // margin-left: 80px;
        margin-right: 48px;
        z-index: 1;
        @include responsives(max-width, $xl-width, (order: 1, margin: 0px));  
      }
    }
  }
}
