@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.header-container {  
  background-color: $blue-color;
  width: 100%;
  min-width: $min-content-width;
  @include flex(center, center, row);

  .header-body {
    padding: 10px 0px 10px $default-padding-left;
    width: 100%;
    max-width: $max-width;
    @include flex(center, space-between, row);
    @include responsives(max-width, $xl-width, (display: flex, flex-direction: column, align-items: center, padding-left: 0px));      
    @include responsives(max-width, $sm-width, (display: flex, flex-direction: column, align-items: center));

    .header-logo {
      height: 41px;
      width: auto;
    }
  
    .header-logo-placeholder {
      visibility: hidden;
      @include responsives(max-width, $xl-width, (display: none));
    }
  
    .header-nav-container {
      @include flex(center, space-between, row);
      @include responsives(max-width, $xl-width, (margin-top: 20px));
  
      .header-nav {
        color: #FFFFFF;
        padding: 0px 25px;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}