@import "../../assets/scss/main";

.status-container {
  background-color: $light-white-color;
  width: 100%;
  height: fit-content;     
  position: relative;  
  min-width: $min-content-width;  
  @include flex(center, center, row);

  .section_bg2 {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 380px;
    object-fit: contain;
  }

  .status-body {
    width: 100%;
    height: 970px;    
    max-width: $max-width;
    padding-left: $default-padding-left;
    padding-top: 125px;
    @include responsives(max-width, $xl-width, (height: 1500px, padding-left: 0px));

    .section {        
      position: relative;    

      &.status {
        @include flex(flex-start, flex-start, row);            
        @include responsives(max-width, $xl-width, (flex-direction:column, align-items:center));

        .status-section{
          padding: 24px;
          width: fit-content;          
          @include responsives(max-width, $xl-width, (margin-bottom: 50px));
        }
      }

      .set_goals_img {
        width: 100%;
        max-width: 600px;
        height: 470px;
        object-fit: contain;
        margin-left: 73px;
        z-index: 1;        
        @include responsives(max-width, $xl-width, (margin: 0px));  
      }
    }
  }
}
