@import '../../assets/scss/variables';
@import "../../assets/scss/main";

.ant-modal.legal-modal {
  width: 100%;
  max-width: $max-width;

  .ant-modal-content {
    @include flex(center, center, row);
  
    .ant-modal-close-icon {
      color: $ml-gray-6;
      height: 32px;
      width: 32px;
      background-color: #10203714;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 32px;
      position: relative;
      top: 16px;
      left: 8px;
      > svg {
        fill: $ml-gray-6;
        height: 32px;
        width: 32px;
      }
    }

    .ant-modal-body {
      padding: 64px 24px 110px 24px;
      width: 100%;
      max-width: 1125px;
      letter-spacing: 0px;

      // .legal-container {
      //   @include flex(center, flex-start, column);

      //   .legal-header {
      //     width: 100%;
      //     height: 140px;
      //     background: $ml-gray-7 0% 0% no-repeat padding-box;
      //     font: normal normal bold 45px/59px Lato;
      //     letter-spacing: 0px;
      //     color: #FFFFFF;
      //   }

        .legal-body {
          @include flex(flex-start, space-between, row);
          @include responsives(max-width, $md-width, (flex-direction: column, align-items: center));

          .side-bar-container {
            padding-right: 24px;
            width: fit-content;
            max-width: 381px;
            @include flex(flex-start, flex-start, column);
            @include responsives(max-width, $md-width, (order: 0, padding-right: 0px));

            .side-bar-title {
              margin-bottom: 8px;
              color: $ml-gray-6;
              font: normal normal bold 16px/23px Lato;
            }

            .side-bar-link {
              margin-top: 16px;
              font: normal normal normal 16px/23px Lato;
              color: $ml-gray-8;

              &:hover {
                color: $ml-brand-blue;
              }

              &.current {
                color: $ml-brand-blue;
                text-decoration: underline;
              }
            }
          }

          .terms-container {
            width: 100%;
            max-width: 744px;
            @include responsives(max-width, $md-width, (order: 1, margin-top: 32px));        
          }
        }
      // }
    }
  }
}
