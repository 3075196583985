@import 'variables';

@mixin flex($align:null, $justify:null, $direction:null) {
  display: flex;
  @if $align != null {
    align-items: $align;
  }
  @if $justify != null {
    justify-content: $justify;
  }
  @if $direction != null {
    flex-direction: $direction;
  }
}

@mixin responsives($direction, $limit, $maps, $important:false) {
  @media ($direction: $limit) {
    @each $property, $value in $maps {
      @if $important == true {
        #{$property}: $value !important;
      } @else {
        #{$property}: $value;
      }
    }    
  }
}

