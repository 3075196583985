@import url('https://fonts.googleapis.com/css?family=Lato');

$blue-button-color: #004EFF;
$blue-button-transparent: #004EFF00;
$blue-color: #0062FF;
$footer-bg-color: #0C1335;
$dark-gray-color: #989DB9;
$light-gray-color: #A7C5FF;
$pink-color: #FFAAC7;
$red-color: #FF6097;
$shadow-red-color: #FF00E2;
$light-white-color: #EAF1FF;

// Jeff's style guide
// Blue colors
$ml-brand-blue: #00A0BC;
// Grey colors
$ml-gray-6: #838C96;
$ml-gray-7: #47525D;
$ml-gray-8: #1B2224;

$footer-font-size: 14px;
$default-font-size: 16px;

$sm-width: 600px;
$md-width: 768px;
$lg-width: 992px;
$xl-width: 1200px;

$max-width: 1366px;
$default-padding-left: 136px;
$max-content-width: 1230px;
$min-content-width: 300px;

// Arrow button
$hover-transition: 180ms cubic-bezier(0.46, 0, 0.3, 1);
$arrow-hover-offset: translateX(3px);
