@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.footer-container {
  
  background-color: $footer-bg-color;
  width           : 100%;
  height          : fit-content;  
  min-width: $min-content-width;
  margin-top: 96px;
  @include flex(center, center, row);

  .footer-body {
    width: 100%;    
    height : 245px;  
    max-width: $max-width;
    padding: 40px $default-padding-left 12px $default-padding-left;
    letter-spacing: 0px;
    @include flex(flex-start, space-between, column);
    @include responsives(max-width, $md-width, (height: fit-content));
    @include responsives(max-width, $sm-width, (padding-left: 10px, padding-right: 10px, width: fit-content, height: fit-content));

    .footer-links {
      @include flex(flex-start, flex-start, row);
      @include responsives(max-width, $md-width, (flex-direction: column, align-items: flex-start));

      .links {
        // flex: 1;
        width: 180px;
        @include flex(flex-start, flex-start, column);    
        @include responsives(max-width, $md-width, (margin-bottom: 32px));

        .header {
          color: $light-gray-color;            
          font: normal normal bold 14px/18px Lato;
          margin-bottom: 8px;
        }

        .link {
          margin-top: 8px;
          font: normal normal normal 14px/17px Lato;
          cursor: pointer;
          color: $dark-gray-color;
  
          &:hover {
            color: #e1e3e6;
          }
        }
  
        &.social {
          .icon-link {
            margin-top: 8px;
            display: inline-flex;
            align-items: center;
            font: normal normal normal 12px/18px Lato;
            color: $dark-gray-color;
  
            svg {
              margin-right: 8px;
              height: 18px;
              width: 18px;
              display: inline-flex;
              align-items: center;
              fill: $dark-gray-color;
            }
  
            &:hover {
              color: #e1e3e6;

              svg {
                fill: #e1e3e6;
              }
            }
          }
        }
      }
    }

    .footer-bottom {
      @include flex(center, flex-start, row);
      width: 100%;      
      @include responsives(max-width, $md-width, (flex-direction: column, align-items: flex-start));

      .powered-by-content {
        margin-right: 12px; 
        @include flex(center, flex-start, row);

        .footer-moblab-logo {
          height: 28px;
          width: auto;
          object-fit: contain;
          margin-right: 3px; 
          margin-left: 4px;      
          cursor: pointer;

          &:active {
            opacity: 0.8;
          }
        }
      }

      .bottom-text {
        color: $dark-gray-color;
        font: normal normal normal 12px/15px Lato;
      }
    }
  }
}