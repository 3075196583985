@import "../../../assets/scss/main.scss";

.support-container {  
  // background: transparent
  //   url(../../../assets/images/img_landing_section_bg3.svg) no-repeat;
  // background-size: cover;    
  width: 100%;
  height: fit-content;
  position: relative;  
  min-width: $min-content-width;  
  @include flex(center, center, row);  

  .section_circle_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-left: -10px;
    margin-top: 30px;
  }

  .support-body {
    padding-top: 276px;
    padding-left: $default-padding-left;
    width: 100%;
    // height: 810px;
    max-width: $max-width;
    @include responsives(max-width, $xl-width, (height: 2000px, padding-left: 0px));

    .section {
      position: relative;    
      // padding-left: $default-padding-left;    
      // max-width: $max-width;    

      &.friend {
        @include flex(flex-start, flex-start, row);
        // @include responsives(max-width, $xl-width, (height: 1100px));    
        // @include responsives(max-width, $xl-width, (flex-direction: column, align-items: center, padding-left: 0px));
        @include responsives(max-width, $xl-width, (flex-direction: column, align-items: center));
  
        .friend-body {
          // padding: 24px;
          width: fit-content;      
          max-width: 500px;
          @include responsives(max-width, $xl-width, (order: 0, margin-bottom: 50px, padding: 24px));
        }
      }
    
      .support_friends_img {
        width: 100%;
        max-width: 600px;
        // height: 450px;
        object-fit: contain;      
        margin-left: 48px;
        margin-right: $default-padding-left;
        @include responsives(max-width, $xl-width, (order: 0, margin: 0px));
      }

      &.monitor {
        padding-top: 96px;
        @include flex(flex-start, flex-start, row);
        // @include responsives(max-width, $xl-width, (height: 1100px));    
        // @include responsives(max-width, $xl-width, (flex-direction: column, align-items: center, padding-left: 0px));
        @include responsives(max-width, $xl-width, (flex-direction: column, align-items: center));
  
        .monitor-body {
          // padding: 24px;
          padding-right: $default-padding-left;
          width: fit-content;      
          max-width: 600px;
          @include responsives(max-width, $xl-width, (order: 0, margin-bottom: 50px, padding: 24px));        
        }
      }
    
      .monitor_goals_img {
        width: 100%;
        max-width: 600px;
        // height: 450px;
        object-fit: contain;      
        // margin-left: 73px;
        margin-right: 48px;
        @include responsives(max-width, $xl-width, (order: 1, margin: 0px));
      }
    }
  }
}
