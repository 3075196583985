@import '../../assets/scss/variables';
@import "../../assets/scss/main";

.container {
  background: $blue-button-transparent 0% 0% no-repeat padding-box;
  border: 1px solid $blue-button-color;
  border-radius: 6px;
  @include flex(center, center, column);
  
  > a {
    margin-bottom: 3px;
    font: normal normal bold 16px/21px Lato;
    color: $blue-button-color;
  }

  &:hover {
    background: $blue-button-color 0% 0% no-repeat padding-box;
    border: 2px solid $blue-button-color;

      > a {
        color: white;
      }
  }
}
