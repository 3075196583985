@import '../../assets/scss/variables';
@import "../../assets/scss/main";

.ant-modal.legal-modal {
  .ant-modal-body {
    .panel {
      width: 100%;
      letter-spacing: 0px;

      h1 {
        margin: 0px;
        // margin-top: 64px;
        color: $ml-gray-8;
        font: normal normal bold 28px/36px Lato;
      }

      h2 {
        margin: 0px;
        font: normal normal bold 24px/30px Lato;
        // color: #989DB9;
        text-transform: uppercase;
      }

      h3 {
        margin: 0px;
        margin-top: 24px;
        color: $ml-gray-8;
        font: normal normal bold 18px/21px Lato;
      }

      h4 {
        margin: 0px;
        margin-top: 4px;
        margin-bottom: 22px;
        color: $ml-gray-6;
        font: normal normal normal 14px/18px Lato;
      }

      p, ul, ol {
        margin: 0px;
        margin-top: 2px;
        color: $ml-gray-8;
        font: normal normal normal 16px/21px Lato;
      }

      ul, ol {
        padding-inline-start: 20px;
      }
    }
  }
}
