@import "variables";
@import "mixins";

.section{    
  @include responsives(max-width, $sm-width, (text-align: left));
  z-index: 1;
  
  .section-title {
    color: #3e80ff;    
    // width: 300px;
    font: normal normal bold 16px/21px Lato;
    line-height: 0px;
    text-transform: uppercase;
  }

  .section-subtitle {
    color: #0C1335;
    // width: 300px;
    margin: 14px 0px;
    font: normal normal bold 45px/59px Lato;
    letter-spacing: 0px;
  }

  .section-text {
    color: #0C1335CC;
    // width: 300px;    
    font: normal normal normal 22px/33px Lato;
    letter-spacing: 0px;
  }
}
